<div class="billing-settings">
  <div class="form">
    <div class="section-label">
      {{ 'settings.billing.paymentMethod' | translate }}
    </div>
  
    <mat-divider></mat-divider>

    <mat-radio-group [value]="paymentMethod" (change)="paymentMethod = $event.value" [disabled]="!auth.isSuperAdmin">
      <ng-container *ngIf="auth.isSuperAdmin || paymentMethod === 'invoice'">
        <mat-radio-button value="invoice">
          {{ 'settings.billing.invoice' | translate }}
        </mat-radio-button>
        <div class="payment-description">
          {{ 'settings.billing.invoiceDescription' | translate }}
        </div>
      </ng-container>
      <mat-radio-button value="card">
        {{ 'settings.billing.card' | translate }}
      </mat-radio-button>
    </mat-radio-group>
  
    <div>
      <button mat-raised-button color="primary" type="button"
              (click)="openPaymentModal()"
              [disabled]="paymentMethod !== 'card' || isProcessingPayment"
              class="payment-button">
      <mat-icon>credit_card</mat-icon>
      {{ paymentMethod === 'card' ? ('settings.billing.proceedToPayment' | translate) : ('settings.billing.selectCardOption' | translate) }}
      <div class="spinner-border spinner-border-sm" role="status" *ngIf="isProcessingPayment"></div>
      </button>
    </div>

    <div *ngIf="auth.isSuperAdmin" class="actions">
      <button mat-raised-button type="button" (click)="savePaymentMethod()">
        {{ 'settings.billing.savePaymentMethod' | translate }}
      </button>
    </div>
  </div>

  <form [formGroup]="form" class="form" (ngSubmit)="onSubmit()">

    <div class="section-label">
      {{ 'settings.billing.yourPlan' | translate }}
    </div>

    <mat-divider></mat-divider>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.planName' | translate }}</mat-label>
      <mat-select formControlName="planName">
        <mat-option *ngFor="let plan of plans" [value]="plan">{{ plan }}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="price-row">
      <mat-form-field appearance="fill" class="price-field">
        <mat-label>{{ 'settings.billing.price' | translate }}</mat-label>
        <input matInput formControlName="price" type="number" step="0.01" min="0">
      </mat-form-field>
    
      <mat-form-field appearance="fill" class="currency-field">
        <mat-label>{{ 'settings.billing.currency' | translate }}</mat-label>
        <mat-select formControlName="currency">
          <mat-option *ngFor="let currency of currencies" [value]="currency">{{ currency }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.planId' | translate }}</mat-label>
      <input matInput formControlName="planId" disabled>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.subscriptionStartDate' | translate }}</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="subscriptionStartDate">
      <mat-hint>YYYY-MM-DD</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.lastInvoiceUserCount' | translate }}</mat-label>
      <input matInput formControlName="lastInvoiceUserCount" type="number" min="0">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.currentUserCount' | translate }}</mat-label>
      <input matInput formControlName="currentUserCount" type="number" min="0">
    </mat-form-field>

    <div class="section-label">
      {{ 'settings.billing.contactInfo' | translate }}
    </div>

    <mat-divider></mat-divider>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.contactName' | translate }}</mat-label>
      <input matInput formControlName="contactName">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.contactPhone' | translate }}</mat-label>
      <input matInput formControlName="contactPhone">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.contactEmail' | translate }}</mat-label>
      <input matInput formControlName="contactEmail">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.companyName' | translate }}</mat-label>
      <input matInput formControlName="companyName">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.country' | translate }}</mat-label>
      <input matInput formControlName="country">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.street' | translate }}</mat-label>
      <input matInput formControlName="street">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.streetNumber' | translate }}</mat-label>
      <input matInput formControlName="streetNumber">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.city' | translate }}</mat-label>
      <input matInput formControlName="city">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.postalCode' | translate }}</mat-label>
      <input matInput formControlName="postalCode">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.state' | translate }}</mat-label>
      <input matInput formControlName="state">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.vatNumber' | translate }}</mat-label>
      <input matInput formControlName="vatNumber">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.vatRate' | translate }}</mat-label>
      <input matInput formControlName="vatRate" type="number" step="1" min="0" max="100">
    </mat-form-field>

    <div class="actions">
      <button mat-raised-button type="submit">{{ 'save' | translate }}</button>
    </div>
  </form>

  <div class="section-report">
    <div class="section-label">
      {{ 'settings.billing.report' | translate }}
    </div>
  
    <mat-divider></mat-divider>
  
    <div *ngIf="auth.isSuperAdmin">
      <form [formGroup]="subdomainsForm" class="form" (ngSubmit)="saveSubdomainsSelection()">
        <div class="subdomain-selection">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>{{ 'settings.billing.chooseSubdomains' | translate }}</mat-label>
            <mat-select formControlName="selectedSubdomains" multiple (selectionChange)="onSelectionChange($event)">
              <div class="search-container search-field">
                <input matInput
                       type="text"
                       [formControl]="searchControl"
                       (input)="filterSubdomains()">
              </div>
        
              <mat-option *ngFor="let sd of filteredSubdomains" [value]="sd">
                {{ sd.domain }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          
          <div class="subdomain-selection">
            <button mat-raised-button type="submit">
              {{ 'settings.billing.saveSelection' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  
    <div class="monthly-stats">
      <ng-container *ngIf="isMainDomain && currentSubdomains && currentSubdomains.length > 0">
        <h3>{{ 'settings.billing.aggregatedStats' | translate }}</h3>
        <div class="monthly-stats-table-container">
          <table mat-table [dataSource]="aggregatedStats">
          
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>{{ 'settings.billing.lastDayOfMonth' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.date | date: 'dd.MM.yyyy' }}</td>
            </ng-container>
    
            <ng-container matColumnDef="numberOfEmployees">
              <th mat-header-cell *matHeaderCellDef>{{ 'settings.billing.numberOfAccounts' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.numberOfEmployees }}</td>
            </ng-container>
        
            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef>{{ 'settings.billing.serviceValue' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.price }} {{ element.currency }}</td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </ng-container>

      <ng-container>
        <h3>{{ 'settings.billing.monthlyStats' | translate }}</h3>
        <h4>{{ currentDomain }}</h4>
        <div class="monthly-stats-table-container">
          <table mat-table [dataSource]="monthlyStats">
          
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>{{ 'settings.billing.lastDayOfMonth' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.date | date: 'dd.MM.yyyy' }}</td>
            </ng-container>
      
            <ng-container matColumnDef="numberOfEmployees">
              <th mat-header-cell *matHeaderCellDef>{{ 'settings.billing.numberOfAccounts' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.numberOfEmployees }}</td>
            </ng-container>
        
            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef>{{ 'settings.billing.serviceValue' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.price }} {{ element.currency }}</td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </ng-container>
    
      <ng-container *ngIf="isMainDomain && currentSubdomains && currentSubdomains.length > 0">
        <h3>{{ 'settings.billing.subdomainStats' | translate }}</h3>
        <div *ngFor="let sd of currentSubdomains" class="monthly-stats-subdomain">
          <h4>{{ sd.domain }}</h4>
          <div class="monthly-stats-table-container">
            <table mat-table [dataSource]="subdomainsStatsMap[sd.domain]">
            
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>{{ 'settings.billing.lastDayOfMonth' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.date | date: 'dd.MM.yyyy' }}</td>
              </ng-container>
    
              <ng-container matColumnDef="numberOfEmployees">
                <th mat-header-cell *matHeaderCellDef>{{ 'settings.billing.numberOfAccounts' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.numberOfEmployees }}</td>
              </ng-container>
          
              <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef>{{ 'settings.billing.serviceValue' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.price }} {{ element.currency }}</td>
              </ng-container>
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
