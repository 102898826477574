import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './auth/login/login.component';
import {ErrorComponent} from './auth/error/error.component';
import {CallbackComponent} from './auth/callback/callback.component';
import {environment} from '../environments/environment';
import {ReactiveFormsModule} from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {MainComponent} from './shared/main/main.component';
import {ToastrModule} from 'ngx-toastr';
import {ApiUrlInterceptor} from './shared/interceptors/api.url.interceptor';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DragDropDirective} from './shared/drag-drop.directive';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {WizardChooseComponent} from './signatures/wizard/wizard-choose/wizard-choose.component';
import {WizardUserComponent} from './signatures/wizard/wizard-user/wizard-user.component';
import {WizardFinalComponent} from './signatures/wizard/wizard-final/wizard-final.component';
import {WizardComponent} from './signatures/wizard/wizard.component';
import {UnitComponent} from './unit/unit.component';
import {SubmitUserComponent} from './signatures/submit-user/submit-user.component';
import {WizzardSuccessComponent} from './signatures/wizard/wizzard-success/wizzard-success.component';
import {WizardUnitComponent} from './signatures/wizard/wizard-unit/wizard-unit.component';
import {GroupComponent} from './group/group.component';
import {PlannedComponent} from './planned/planned.component';
import {DatePipe, registerLocaleData} from '@angular/common';
import localePl from '@angular/common/locales/pl';
import localeEs from '@angular/common/locales/es';
import {PlanComponent} from './plan/plan.component';
import {TranslationsOnlyBlankComponent} from './shared/blank/translations-only-blank.component';
import {SubscriptionSuccessComponent} from './plan/subscription-success/subscription-success.component';
import {SubscriptionFailedComponent} from './plan/subscription-failed/subscription-failed.component';
import {FinanceComponent} from './finance/finance.component';
import {ClientComponent} from './client/client.component';
import {AuthGuard} from './auth/guard/auth.guard';
import {EmployeesModule} from './employees/employees.module';
import {StoreModule} from '@ngrx/store';
import {employeesReducer} from './employees/employees.reducer';
import {EffectsModule} from '@ngrx/effects';
import {EmployeesEffects} from './employees/employees.effects';
import {SharedModule} from './shared/shared.module';
import {ConfirmationDialogComponent} from './shared/confirmation-dialog/confirmation-dialog.component';
import {SettingsModule} from './settings/settings.module';
import {AddonsModule} from './addons/addons.module';
import {PlaceholdersComponent} from './signatures/placeholders/placeholders.component';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService} from '@angular/fire/analytics';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {AccountsModule} from './accounts/accounts.module';
import {CurrentDomainInterceptor} from './shared/interceptors/current-domain.interceptor';
import {UserAdminsComponent} from './user-admins/user-admins.component';
import {UserAdminsCreateComponent} from './user-admins/user-admins-create/user-admins-create.component';
import {UserAdminsEditDialogComponent} from './user-admins/user-admins-edit-dialog/user-admins-edit-dialog.component';
import {SignaturesModule} from './signatures/signatures.module';
import {SignatureEditComponent} from './signatures/signature-edit/signature-edit.component';
import {SignatureCreateComponent} from './signatures/signature-create/signature-create.component';
import {AddonsComponent} from './signatures/addons/addons.component';
import {SignatureDetailsComponent} from './signatures/signature-details/signature-details.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {SuperAdminPartnersModule} from './super-admin-partners/super-admin-partners.module';
import {PartnerModule} from './partner/partner.module';
import {ClientEditDialogComponent} from './client/client-edit-dialog/client-edit-dialog.component';
import {StripUndefinedParamsInterceptor} from './shared/interceptors/strip-undefined-params.interceptor';
import { EmployeeSettingsFormDialogComponent } from './employee-settings/employee-settings-form-dialog/employee-settings-form-dialog.component';
import { MediaModule } from './media/media.module';
import { ExpiredTrialComponent } from './expired-trial/expired-trial.component';
import {SignatureInstallationStateModule} from './signature-installation-state/signature-installation-state.module';
import {SignaturesNavigationComponent} from './signatures/signatures-navigation/signatures-navigation.component';
import {SignaturesPredefinedComponent} from './signatures/signatures-predefined/signatures-predefined.component';
import {EditSignaturePredefinedComponent} from './signatures/edit-signature-predefined/edit-signature-predefined.component';
import {DragulaModule} from 'ng2-dragula';
import { MatListModule } from '@angular/material/list';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import {MatButtonToggle} from '@angular/material/button-toggle';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ErrorComponent,
    CallbackComponent,
    MainComponent,
    DragDropDirective,
    SignatureCreateComponent,
    SignatureDetailsComponent,
    SignatureEditComponent,
    WizardChooseComponent,
    WizardUserComponent,
    WizardUnitComponent,
    WizardFinalComponent,
    WizardComponent,
    UnitComponent,
    SubmitUserComponent,
    WizzardSuccessComponent,
    GroupComponent,
    PlannedComponent,
    PlanComponent,
    TranslationsOnlyBlankComponent,
    SubscriptionSuccessComponent,
    SubscriptionFailedComponent,
    FinanceComponent,
    ClientComponent,
    ConfirmationDialogComponent,
    PlaceholdersComponent,
    UserAdminsComponent,
    UserAdminsCreateComponent,
    UserAdminsEditDialogComponent,
    AddonsComponent,
    ClientEditDialogComponent,
    EmployeeSettingsFormDialogComponent,
    ExpiredTrialComponent,
    SignaturesNavigationComponent,
    SignaturesPredefinedComponent,
    EditSignaturePredefinedComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
        timeOut: 10000,
        positionClass: 'toast-bottom-right',
        closeButton: true
    }),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    EmployeesModule,
    SettingsModule,
    AddonsModule,
    SharedModule,
    AccountsModule,
    SuperAdminPartnersModule,
    SignaturesModule,
    MediaModule,
    StoreModule.forRoot({ employee: employeesReducer }),
    EffectsModule.forRoot([EmployeesEffects]),
    MatProgressSpinnerModule,
    PartnerModule,
    DragulaModule.forRoot(),
    SignatureInstallationStateModule,
    MatListModule,
    CodemirrorModule,
    MatButtonToggle,
    FormsModule,
  ],
  providers: [
    AuthGuard,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: ApiUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CurrentDomainInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: StripUndefinedParamsInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    ScreenTrackingService,
    UserTrackingService,
  ],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeEs, 'es');
    registerLocaleData(localePl, 'pl');
  }
}

