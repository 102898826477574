import {PaginatedData} from 'src/app/shared/model/table';

export interface EmployeeListSearchParams {
  phrase?: string;
  pager: {
    limit: number;
    page: number;
  };
  sortBy?: 'id' | 'fullName' | 'email' | 'modifiedAt' | 'createdAt' | 'signatureInstallationAt';
  order?: string;
  signatureTemplateId?: string;
  signatureInstalled?: TriStateCheckbox | null;
  excludedFromSignatures?: TriStateCheckbox | null;
  groupEmail?: string;
  viewType?: ViewType;
}

export enum TriStateCheckbox {
  ALL = 'ALL',
  ONLY_ENABLED = 'ONLY_ENABLED',
  ONLY_DISABLED = 'ONLY_DISABLED',
}

export interface ChangeExcludedFromSignaturesParams {
  id: number;
  excludedFromSignatures: boolean;
}

export interface EmployeeRequest {
  subject: string;
  body: string;
  target: string;
}

export interface EmployeeListItem {
  id: string;
  fullName?: string;
  email?: string;
  givenName?: string;
  familyName?: string;
  excludedFromSignatures?: boolean;
  canBeExcludedFromSignatures: boolean;
  signatureInstallationAt?: Date;
  emptyFieldsCount?: number;
  createdAt: Date;
  modifiedAt?: Date;
  groupEmail: string;
  isAlias?: boolean;
  localData?: boolean;
}

export interface SingleEmployeeResponse {
  id: string;
  fullName: string;
  email: string;
  emptyFieldsCount: number;
  createdAt: Date;
  modifiedAt?: Date;
  excludedFromSignatures: boolean;
  skypeId: string;
  phoneNumber: string;
  picture: string;
  localAvatar?: boolean;
}

export interface CurrentUserResponse {
  id: string;
  name: string;
  isAdmin: boolean;
  email: string;
  emptyFieldsCount: number;
  createdAt: Date;
  modifiedAt?: Date;
  excludedFromSignatures: boolean;
  skypeId: string;
  phoneNumber: string;
  picture: string;
  localAvatar?: boolean;
  signature: string;
}

export interface Employee {
  id: number;
  fullName?: string;
  givenName?: string;
  familyName?: string;
  email?: string;
  jobTitle?: string;
  mobileNumber?: string;
  phoneNumber?: string;
  skypeId?: string;
  picture?: string;
  localAvatar?: boolean;
  excludedFromSignatures?: boolean;
  emptyFieldsCount?: number;
  createdAt: Date;
  modifiedAt?: Date;
  signatureInstallationAt?: Date;
  signature?: string;
  signatureTemplateId: string;
  dynamicVariables: any;
  customLinks: Link[];
  handwrittenSignature?: string;
  canBeExcludedFromSignatures: boolean;
  thumbnailPhotoUrl?: string;
  bannerDisabled?: boolean;
  aliases?: EmailAddressAlias[];
  localData?: boolean;
}

export interface EmailAddressAlias {
  email: string;
  aliasName?: string | null;
  isAlias: boolean;
  fullName?: string;
}

interface Link {
  text: string;
  url: string;
}

export interface EmployeesState {
  employees: PaginatedData<EmployeeListItem>;
  params: EmployeeListSearchParams;
  currentEmployee: Employee;
  employee: Employee;
  isSyncInProgress: boolean
}

export const defaultEmployeeListPager = {
  limit: 10,
  page: 0
};


export interface EmployeeDataImportVerificationResult {
  preview?: Array<Record<string, any>>;
  data?: Record<string, any>;
  warnings?: Array<{ key: WarningKey; value?: any }>;
  errors?: Array<{ key: ErrorKey; value?: any }>;
  meta?: {
    columns: string[];
    numberOfEmployees: number;
  };
}

export interface EmployeeDataImportResult {
  numberOfInstallations: number;
  numberOfChanges: number;
}

export type ViewType = 'EMPLOYEES' | 'ALIASES' | 'BOTH';

export type WarningKey = 'IGNORED_VARIABLES' | 'NOT_EXISTING_EMPLOYEES';

export type ErrorKey = 'NO_EMAIL_FIELD' | 'DUPLICATED_EMAIL_FIELD' | 'NO_HEADER_ROW' | 'NO_DATA';
