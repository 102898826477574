<div class="cancel-container">
  <div class="crossmark-circle">
    <div class="background"></div>
    <div class="crossmark"></div>
  </div>
  <h3>{{ 'settings.billing.cancel.paymentCancelled' | translate }}</h3>
  <span>{{ 'settings.billing.cancel.tryAgainMessage' | translate }}</span>
    <button mat-raised-button color="primary" (click)="retryPayment()"
    [disabled]="isRetryingPayment">
  {{ 'settings.billing.cancel.tryAgain' | translate }}
  <div class="spinner-border spinner-border-sm" role="status" *ngIf="isRetryingPayment"></div>
  </button>
    <button mat-raised-button color="warn" type="button" (click)="backToBilling()">
    {{ 'settings.billing.success.close' | translate }}
  </button>
</div>
