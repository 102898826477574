import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LANG } from '../../app.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  sending = false;
  redirecting = false;
  email: string = '';
  successMessage: string | null = null;
  errorMessage: string | null = null;

  languages = ['en', 'es', 'pl'];
  selectedLanguage: string;

  constructor(
    private auth: AuthService,
    private router: Router,
    private translate: TranslateService,
  ) {
    this.translate.addLangs(this.languages);
    this.translate.setDefaultLang('en');

    const browserLang = this.translate.getBrowserLang() || 'en';
    const defaultLang = localStorage.getItem(LANG) || (browserLang.match(/en|es|pl/) ? browserLang : 'en');

    this.translate.use(defaultLang);
    this.selectedLanguage = defaultLang;
  }

  ngOnInit(): void {
    const sub = this.auth.user$.subscribe(user => user && user.uid ?
      this.router.navigate(['/accounts']).finally(() => sub.unsubscribe()) :
      sub.unsubscribe());
  }

  changeLanguage(event: any): void {
    const newLang = event.value;
    localStorage.setItem(LANG, newLang);
    this.translate.use(newLang);
    this.selectedLanguage = newLang;
  }

  async loginWithGoogle(): Promise<void> {
    this.redirecting = true;
    await this.auth.loginWithGoogle().finally(() => (this.redirecting = false));
  }

  async sendEmailLink(): Promise<void> {
    if (!this.email) {
      return;
    }

    this.sending = true;
    try {
      await this.auth.sendSignWithEmail(this.email, this.selectedLanguage);
    } finally {
      this.sending = false;
    }
  }
}
