<div class="logo-container">
  <div class="text-center">
    <img src="/assets/img/gSignature-logo.svg" alt="Logo" class="logo">
  </div>
  <div class="text-center">
    <h1 class="header">{{ 'login.title' | translate }}</h1>
  </div>
  <div class="form-container">
    <button class="btn btn-google" [disabled]="redirecting" (click)="loginWithGoogle()">
      <img src="/assets/img/google-logo.svg" alt="Google Logo" class="google-icon">
      {{ (redirecting ? 'login.redirecting' : 'login.google') | translate }}
    </button>
    <div class="separator">{{ 'login.or' | translate }}</div>
    <div class="email-login">
      <input 
        type="email" 
        [(ngModel)]="email" 
        [placeholder]="'login.emailPlaceholder' | translate" 
        class="email-input"
        (keyup.enter)="sendEmailLink()" />
      <button 
        class="btn btn-email" 
        (click)="sendEmailLink()" 
        [disabled]="sending || !email">
        {{ (sending ? 'login.sending' : 'login.email') | translate }}
      </button>
    </div>
  </div>
  <div class="language-selector-login">
    <mat-form-field appearance="fill">
      <mat-select [value]="selectedLanguage" (selectionChange)="changeLanguage($event)">
        <mat-option *ngFor="let language of languages" [value]="language">{{ language.toUpperCase() }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
