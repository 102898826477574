import {Component, OnInit} from '@angular/core';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html'
})
export class CallbackComponent implements OnInit {
  error;
  longLoading = false;

  constructor(private auth: AuthService, private router: Router) {
  }

  async ngOnInit() {
    setTimeout(() => (this.longLoading = true), 10000);
    try {
      if (this.auth.isLoginWithLink()) {
        await this.auth.loginWithEmailLink();
      } else {
        await this.auth.getRedirectResult();
      }
      await this.router.navigate(['']);
    } catch (e) {
      console.error('Login error:', e);
      await this.router.navigate(['/login/error']);
    }
  }
}
