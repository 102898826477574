import {createSelector} from '@ngrx/store';
import {AccountsState, CURRENT_ACCOUNT_LOCAL_STORAGE_KEY, CURRENT_PARTNER_ID_LOCAL_STORAGE_KEY} from './accounts.interface';
import {
  AccountActions,
  CHANGE_ACCOUNT,
  CHANGE_ACCOUNT_SUCCESS,
  CHANGE_PARTNER_SUCCESS,
  FETCH_AVAILABLE_ACCOUNT_SUCCESS,
  RESET_ACCOUNT_SUCCESS
} from './accounts.actions';

export const accountsStateName = 'accounts';
export const initialState: AccountsState = {
  currentAccount: window.localStorage.getItem(CURRENT_ACCOUNT_LOCAL_STORAGE_KEY) || undefined,
  currentPartnerId: window.localStorage.getItem(CURRENT_PARTNER_ID_LOCAL_STORAGE_KEY) || undefined,
  availableAccounts: [],
  availablePartners: [],
};

export function accountsReducer(
  state: AccountsState = initialState,
  action: AccountActions
): AccountsState {
  switch (action.type) {
    case CHANGE_ACCOUNT:
      return { ...state, currentAccount: action.payload };
    case CHANGE_ACCOUNT_SUCCESS:
      return {...state, currentAccount: action.payload};
    case CHANGE_PARTNER_SUCCESS:
      return {...state, currentPartnerId: action.payload};
    case RESET_ACCOUNT_SUCCESS:
      return {...state, currentAccount: undefined};
    case FETCH_AVAILABLE_ACCOUNT_SUCCESS:
      return {...state, availableAccounts: action.payload.clients, availablePartners: action.payload.partners};

    default: {
      return state;
    }
  }
}

export const getAccountsState = state => state?.[accountsStateName];

export const getCurrentAccountState = (state: AccountsState) => state?.currentAccount;
export const getAccountContextState = ({currentPartnerId, currentAccount}: AccountsState) => ({
  currentAccount,
  currentPartnerId,
});
export const getAvailableAccountsState = (state: AccountsState) => state?.availableAccounts;
export const getAvailablePartnersState = (state: AccountsState) => state?.availablePartners;

export const getCurrentAccount = createSelector(getAccountsState, getCurrentAccountState);
export const getAccountContextStateSelector = createSelector(getAccountsState, getAccountContextState);
export const getAvailableAccountsSelector = createSelector(getAccountsState, getAvailableAccountsState);
export const getAvailablePartnersSelector = createSelector(getAccountsState, getAvailablePartnersState);

