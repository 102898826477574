<nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light" id="sidebar" *ngIf="!hideMenu">
  <mat-sidenav opened="true">
    <div class="menu-avatar">
      <img
        class="avatar-img mat-elevation-z8"
        [src]="appUser.thumbnailPhotoUrl" *ngIf="appUser?.thumbnailPhotoUrl"
      />

      <h4 class="name">
        {{ (user | async)?.displayName }}
      </h4>
      <div class="email">
        {{ (user | async)?.email }}
      </div>
      <span class="current-domain">{{ (account$ | async) }}</span>
    </div>

    <app-trial-info/>

    <mat-divider></mat-divider>

    <mat-accordion *ngIf="auth.isSuperAdmin || auth.isCompanyManager || auth.isPartner">
      <mat-expansion-panel class="mat-expansion-panel" expanded="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div fxLayout="row" fxLayoutAlign="space-between center"
                 [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: false }">
              <a class="nav-link" [routerLink]="['/signatures']" [routerLinkActive]="'active'"
                 *ngIf="auth.hasCompanyManagerAccess">
                <button mat-button class="menu-button">
                  <mat-icon>menu</mat-icon>
                  <span>{{ 'menu.panel' | translate }}</span>
                </button>
              </a>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <a class="nav-link" [routerLink]="['/signatures/custom']" [routerLinkActive]="'active'"
           *ngIf="auth.hasCompanyManagerAccess">
          <button mat-button class="menu-button">
            <mat-icon>email</mat-icon>
            <span>{{ 'menu.signatures.custom' | translate }}</span>
          </button>
        </a>

        <a class="nav-link" [routerLink]="['/signatures/library']" [routerLinkActive]="'active'"
           *ngIf="auth.hasCompanyManagerAccess">
          <button mat-button class="menu-button">
            <mat-icon>library_books</mat-icon>
            <span>{{ 'menu.library' | translate }}</span>
          </button>
        </a>

        <a class="nav-link" href="https://generator.gsignature.com/" target="_blank"
          *ngIf="auth.hasCompanyManagerAccess">
          <button mat-button class="menu-button">
            <mat-icon>construction</mat-icon>
            <span>{{ 'menu.generator' | translate }}</span>
          </button>
        </a>

        <a class="nav-link" [routerLink]="['/addons']" [routerLinkActive]="'active'"
           *ngIf="auth.hasCompanyManagerAccess">
          <button mat-button class="menu-button">
            <mat-icon>add_box</mat-icon>
            <span>{{ 'menu.addons' | translate }}</span>
          </button>
        </a>

        <a class="nav-link" [routerLink]="['/media']" [routerLinkActive]="'active'"
           *ngIf="auth.hasCompanyManagerAccess">
          <button mat-button class="menu-button">
            <mat-icon>perm_media</mat-icon>
            <span>{{ 'menu.media' | translate }}</span>
          </button>
        </a>

        <a class="nav-link" [routerLink]="['/planned']" [routerLinkActive]="'active'"
           *ngIf="auth.hasCompanyManagerAccess">
          <button mat-button class="menu-button">
            <mat-icon>timelapse</mat-icon>
            <span>{{ 'menu.planned' | translate }}</span>
          </button>
        </a>

        <a class="nav-link" [routerLink]="['/stats']" [routerLinkActive]="'active'"
           *ngIf="auth.hasCompanyManagerAccess">
          <button mat-button class="menu-button">
            <mat-icon>query_stats</mat-icon>
            <span>{{ 'menu.analytics' | translate }}</span>
          </button>
        </a>


      </mat-expansion-panel>
    </mat-accordion>

    <a class="nav-link" [routerLink]="['/employees']" [routerLinkActive]="'active'"
       *ngIf="auth.hasCompanyManagerAccess">
      <button mat-button class="menu-button">
        <mat-icon>contact_mail</mat-icon>
        <span>{{ 'menu.employees' | translate }}</span>
      </button>
    </a>

    <mat-accordion *ngIf="auth.isSuperAdmin || auth.isCompanyManager || auth.isPartner">
      <mat-expansion-panel class="mat-expansion-panel" expanded="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div fxLayout="row" fxLayoutAlign="space-between center"
                 [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: false }">
              <a class="nav-link" [routerLink]="['/settings']" [routerLinkActive]="'active'">
                <button mat-button class="menu-button">
                  <mat-icon>settings</mat-icon>
                  <span>{{ 'menu.settings' | translate }}</span>
                </button>
              </a>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <a class="nav-link" [routerLink]="['/user-admins']" [routerLinkActive]="'active'"
           *ngIf="auth.isSuperAdmin || auth.isCompanyManager || auth.isPartner">
          <button mat-button class="menu-button">
            <mat-icon>stars</mat-icon>
            <span>{{ 'menu.admins' | translate }}</span>
          </button>
        </a>

        <a class="nav-link" [routerLink]="['/settings/company']" [routerLinkActive]="'active'"
           *ngIf="auth.hasCompanyManagerAccess">
          <button mat-button class="menu-button">
            <mat-icon>corporate_fare</mat-icon>
            <span>{{ 'menu.companyData' | translate }}</span>
          </button>
        </a>

        <a class="nav-link" [routerLink]="['/settings/employees']" [routerLinkActive]="'active'"
           *ngIf="auth.hasCompanyManagerAccess">
          <button mat-button class="menu-button">
            <mat-icon>group</mat-icon>
            <span>{{ 'menu.employeeData' | translate }}</span>
          </button>
        </a>

        <a class="nav-link" [routerLink]="['/settings/advanced']" [routerLinkActive]="'active'"
           *ngIf="auth.hasCompanyManagerAccess">
          <button mat-button class="menu-button">
            <mat-icon>build</mat-icon>
            <span>{{ 'menu.advanced' | translate }}</span>
          </button>
        </a>

        <a class="nav-link" [routerLink]="['/settings/integration']" [routerLinkActive]="'active'"
           *ngIf="auth.hasCompanyManagerAccess">
          <button mat-button class="menu-button">
            <mat-icon>link</mat-icon>
            <span>{{ 'menu.integration' | translate }}</span>
          </button>
        </a>

        <a class="nav-link" [routerLink]="['/settings/billing']" [routerLinkActive]="'active'"
           *ngIf="auth.hasCompanyManagerAccess">
          <button mat-button class="menu-button">
            <mat-icon>payment</mat-icon>
            <span>{{ 'menu.billing' | translate }}</span>
          </button>
        </a>

        <a class="nav-link" [routerLink]="['/settings/api']" [routerLinkActive]="'active'"
           *ngIf="auth.hasCompanyManagerAccess">
          <button mat-button class="menu-button">
            <mat-icon>code</mat-icon>
            <span>{{ 'menu.api' | translate }}</span>
          </button>
        </a>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-divider *ngIf="auth.hasCompanyManagerAccess"></mat-divider>

    <a class="nav-link" [routerLink]="['/my-account']" [routerLinkActive]="'active'">
      <button mat-button class="menu-button">
        <mat-icon>account_circle</mat-icon>
        <span>{{ 'menu.myAccount' | translate }}</span>
      </button>
    </a>

    <a class="nav-link" [routerLink]="['/accounts']" *ngIf="auth.hasCompanyManagerAccess && auth.canChangeAccounts">
      <button mat-button class="menu-button">
        <mat-icon>supervisor_account</mat-icon>
        <span>{{ 'menu.changeAccount' | translate }}</span>
      </button>
    </a>

    <a class="nav-link" (click)="auth.logOut()" [routerLinkActive]="'active'">
      <button mat-button class="menu-button">
        <mat-icon>exit_to_app</mat-icon>
        <span>{{ 'menu.logout' | translate }}</span>
      </button>
    </a>

    <mat-divider *ngIf="auth.hasCompanyManagerAccess"></mat-divider>

    <a class="nav-link" [routerLink]="['/admin/client']" [routerLinkActive]="'active'" *ngIf="auth.isSuperAdmin">
      <button mat-button class="menu-button">
        <mat-icon>person_pin_circle</mat-icon>
        <span>{{ 'menu.clients' | translate }}</span>
      </button>
    </a>

    <a class="nav-link" [routerLink]="['/admin/partners']" [routerLinkActive]="'active'" *ngIf="auth.isSuperAdmin">
      <button mat-button class="menu-button">
        <mat-icon>supervisor_account</mat-icon>
        <span>{{ 'menu.partners' | translate }}</span>
      </button>
    </a>

    <div class="language-selector">
      <mat-form-field appearance="fill">
        <mat-select [value]="selectedLanguage" (selectionChange)="change($event)">
          <mat-option *ngFor="let language of languages" [value]="language">{{ language.toUpperCase() }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-sidenav>
</nav>

<div class="no-google-sync main-content"
     *ngIf="appUser !== undefined && !appUser.lastGoogleSync && !appUser.localData && router.url !== '/accounts'">
  <app-closable-panel variant="warning">
    {{ 'settings.integration.warning1' | translate }}
    <a [routerLink]="['/settings', 'integration']">{{ 'settings.integration.warning2' | translate }}</a>
    {{ 'settings.integration.warning3' | translate }}
  </app-closable-panel>
</div>

<app-signature-installation-progress-toast/>

<div class="main-content">
  <div class="container">
    <router-outlet></router-outlet>
  </div>
</div>
