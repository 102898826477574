import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { getCurrentAccount } from '../../accounts/accounts.reducer';
import { ClientMonthlyStats } from '../../settings/settings.interface';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { BillingSettings } from '../../settings/settings.interface';
import { BillingSettingsService } from './billing-settings.service';
import { GetBillingSettingsAction, ChangeBillingSettingsAction } from '../../settings/settings.actions';
import { getBillingSettingsSelector } from '../../settings/settings.reducer';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-billing-settings',
  templateUrl: './billing-settings.component.html',
  styleUrls: ['./billing-settings.component.scss']
})
export class BillingSettingsComponent implements OnInit {
  currentDomain: string | null = null;

  monthlyStats: ClientMonthlyStats[] = [];
  displayedColumns: string[] = ['date', 'numberOfEmployees', 'price'];

  plans = ['gSignature Basic', 'gSignature Plus', 'gSignature Pro'];
  currencies = ['USD', 'EUR', 'PLN'];

  paymentMethod: string;
  isProcessingPayment = false;

  allSubdomains: Array<{ domain: string }> = [];
  filteredSubdomains: Array<{ domain: string }> = [];
  selectedSubdomainsControl = new UntypedFormControl([]);
  selectedSubdomainsSet = new Set<string>();
  searchControl = new FormControl('');

  isMainDomain = false;
  subdomainsStatsMap: { [domain: string]: ClientMonthlyStats[] } = {};
  aggregatedStats: ClientMonthlyStats[] = [];
  currentSubdomains: Array<{ domain: string }> = [];

  form = new UntypedFormGroup({
    planName: new UntypedFormControl({ value: '', disabled: true }),
    price: new UntypedFormControl({ value: '', disabled: true }, [Validators.pattern(/^\d+(\.\d{1,2})?$/)]),
    currency: new UntypedFormControl({ value: '', disabled: true }),
    planId: new UntypedFormControl({ value: '', disabled: true }),
    subscriptionStartDate: new UntypedFormControl({ value: '', disabled: true }),
    lastInvoiceUserCount: new UntypedFormControl({ value: '', disabled: true }),
    currentUserCount: new UntypedFormControl({ value: '', disabled: true }),
    contactName: new UntypedFormControl(''),
    contactPhone: new UntypedFormControl(''),
    contactEmail: new UntypedFormControl(''),
    companyName: new UntypedFormControl(''),
    country: new UntypedFormControl(''),
    street: new UntypedFormControl(''),
    streetNumber: new UntypedFormControl(''),
    city: new UntypedFormControl(''),
    postalCode: new UntypedFormControl(''),
    state: new UntypedFormControl(''),
    vatNumber: new UntypedFormControl(''),
    vatRate: new UntypedFormControl('')
  });

  constructor(
    private billingSettingsService: BillingSettingsService,
    private store: Store,
    public auth: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.store.select(getCurrentAccount).subscribe(domain => {
      this.currentDomain = domain;
    });

    if (this.auth.isSuperAdmin) {
      this.enableFieldsForSuperAdmin();
    }

    this.store.dispatch(new GetBillingSettingsAction());
    this.store.select(getBillingSettingsSelector)
      .subscribe((billingSettings: BillingSettings) => {
        if (billingSettings && this.currentDomain) {
          this.fillForm(billingSettings);
          if (this.auth.isSuperAdmin) {
            this.loadDomainsAndSubdomains(billingSettings);
          }
          this.initializeStatsView(billingSettings);
        }
      });

      this.searchControl.valueChanges.subscribe(() => this.filterSubdomains());
  }

  filterSubdomains() {
    const searchValue = this.searchControl.value?.toLowerCase() || '';

    this.filteredSubdomains = this.allSubdomains.filter(sd =>
      (sd.domain.toLowerCase().includes(searchValue) || this.selectedSubdomainsSet.has(sd.domain))
      && sd.domain !== this.currentDomain
    );
  }

  onSelectionChange(event: any) {
    const selectedValues = event.value as Array<{ domain: string }>;
    this.selectedSubdomainsSet.clear();

    selectedValues.forEach(selected => {
      this.selectedSubdomainsSet.add(selected.domain);
    });
  }

  private initializeStatsView(billingSettings: BillingSettings) {
    const hasSubdomains = (billingSettings.subdomains && billingSettings.subdomains.length > 0);

    this.currentSubdomains = billingSettings.subdomains || [];

    if (hasSubdomains) {
      this.isMainDomain = true;
      this.billingSettingsService.getMonthlyStats(this.currentDomain!).subscribe(stats => {
        this.monthlyStats = stats;
      });

      this.currentSubdomains.forEach(sd => {
        this.billingSettingsService.getMonthlyStats(sd.domain).subscribe(subStats => {
          this.subdomainsStatsMap[sd.domain] = subStats;
        });
      });

      this.billingSettingsService.getAggregatedMonthlyStats(this.currentDomain!).subscribe(aggStats => {
        this.aggregatedStats = aggStats;
      });

    } else {
      this.billingSettingsService.getMonthlyStats(this.currentDomain!).subscribe(stats => {
        this.monthlyStats = stats;
      });
    }
  }

  fillForm = (billingSettings: BillingSettings) => {
    this.form.patchValue({
      planName: billingSettings.planName || '',
      price: billingSettings.price || 1,
      currency: billingSettings.currency || 'USD',
      planId: billingSettings.planId || '',
      subscriptionStartDate: new Date(billingSettings.subscriptionStartDate),
      lastInvoiceUserCount: billingSettings.lastInvoiceUserCount,
      currentUserCount: billingSettings.currentUserCount,
      contactName: billingSettings.contactName || '',
      contactPhone: billingSettings.contactPhone || '',
      contactEmail: billingSettings.contactEmail || '',
      companyName: billingSettings.companyName || '',
      country: billingSettings.country || '',
      street: billingSettings.street || '',
      streetNumber: billingSettings.streetNumber || '',
      city: billingSettings.city || '',
      postalCode: billingSettings.postalCode || '',
      state: billingSettings.state || '',
      vatNumber: billingSettings.vatNumber || '',
      vatRate: billingSettings.vatRate || 0,
    });

    this.paymentMethod = billingSettings.paymentMethod || 'card';
  };

  subdomainsForm = new UntypedFormGroup({
    selectedSubdomains: new UntypedFormControl([])
  });

  private loadDomainsAndSubdomains(billingSettings: BillingSettings): void {
    this.billingSettingsService.getAllDomains().subscribe({
      next: (domains) => {
        this.allSubdomains = domains.map((domain) => ({ domain }));
        this.filteredSubdomains = this.allSubdomains.filter(sd => sd.domain !== this.currentDomain);
        this.loadSavedSubdomains(billingSettings.subdomains || []);
      }
    });
  }

  private loadSavedSubdomains(subdomains: Array<{ domain: string }>): void {
    const matchedSubdomains = subdomains.map((selected) =>
      this.allSubdomains.find((subdomain) => subdomain.domain === selected.domain)
    ).filter(Boolean);

    this.subdomainsForm.get('selectedSubdomains')?.setValue(matchedSubdomains);

    matchedSubdomains.forEach(subdomain => {
      if (subdomain) {
        this.selectedSubdomainsSet.add(subdomain.domain);
      }
    });
  }

  savePaymentMethod(): void {
    this.billingSettingsService.updatePaymentMethod(this.paymentMethod).subscribe({
      next: () => {
        this.toastr.success(this.translate.instant('settings.billing.paymentMethodUpdateSuccess'));
      },
      error: () => {
        this.toastr.error(this.translate.instant('settings.billing.paymentMethodUpdateError'));
      }
    });
  }

  saveSubdomainsSelection(): void {
    const selected = this.subdomainsForm.get('selectedSubdomains')?.value || [];
    this.billingSettingsService.updateSubdomains(this.currentDomain!, selected).subscribe(() => {
      this.toastr.success(this.translate.instant('settings.billing.subdomainsSuccess'));
    });
  }

  enableFieldsForSuperAdmin() {
    ['planName', 'price', 'currency', 'subscriptionStartDate'].forEach(field => this.form.get(field)?.enable());
  }

  onSubmit = () => {
    if (this.form.valid) {
      const formValue = this.form.getRawValue();

      if (formValue.subscriptionStartDate instanceof Date) {
        formValue.subscriptionStartDate = formValue.subscriptionStartDate.toISOString();
      } else {
        formValue.subscriptionStartDate = formValue.subscriptionStartDate.format('YYYY-MM-DD');
      }

      this.store.dispatch(new ChangeBillingSettingsAction(formValue));

      this.toastr.success(this.translate.instant('settings.billing.updateSuccess'));
    } else {
      this.form.markAllAsTouched();
    }
  };


  async openPaymentModal(): Promise<void> {
    if (this.paymentMethod === 'card') {
      this.isProcessingPayment = true;
      this.billingSettingsService.createCheckoutSession().subscribe({
        next: (response: { url: string }) => {
          window.location.href = response.url;
        },
        error: () => {
          this.toastr.error(this.translate.instant('settings.billing.paymentError'));
          this.isProcessingPayment = false;
        }
      });
    } else {
      this.toastr.info(this.translate.instant('settings.billing.invoiceSelected'));
    }
  }
}
