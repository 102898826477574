<app-loading *ngIf="loading"></app-loading>

<div *ngIf="!loading">
  <mat-card-header>
    <button mat-raised-button (click)="fileInput.click()" color="accent">
      <ng-container>{{'employee.avatar.selectFile' | translate}}</ng-container>
    </button>
  </mat-card-header>

  <mat-card-content>
    <div class="mat-card-content">
      <input hidden type="file" #fileInput (change)="onFileSelect($event)">

      <div *ngIf="localAvatar && !uploadedImage" class="thumbnail-wrapper">
        <img [src]="thumbnailPhotoUrl" class="thumbnail-image">
      </div>

      <div class="image-cropper-wrapper">
        <div *ngIf="isImageCropperLoading" class="cropper-loader">
          <app-loading></app-loading>
        </div>
        <div *ngIf="!isImageCropperLoading">
          <image-cropper
            [imageFile]="finalImage"
            [maintainAspectRatio]="true"
            [resizeToWidth]="256"
            [cropperMinWidth]="128"
            [onlyScaleDown]="true"
            [roundCropper]="false"
            [canvasRotation]="0"
            [alignImage]="'left'"
            [aspectRatio]="1 / 1"
            format="png"
            (imageCropped)="imageCropped($event)"
            [backgroundColor]="backgroundColor"
          ></image-cropper>
          <br>
        </div>
      </div>
      
      <div *ngIf="uploadedImage">
        <h3>{{ 'employee.avatar.backgroundSettings' | translate }}</h3>

        <div class="background-actions">
          <button mat-stroked-button (click)="drawAvatarWithColor('')">{{'employee.avatar.transparent' | translate}}</button>
          <button mat-stroked-button (click)="drawOriginalAvatar()">{{'employee.avatar.original' | translate}}</button>
          <button mat-stroked-button (click)="drawAvatarWithColor('black')">{{'employee.avatar.black' | translate}}</button>
          <button mat-stroked-button (click)="drawAvatarWithColor('white')">{{'employee.avatar.white' | translate}}</button>
        </div>
  
        <form class="custom-color-form">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'employee.avatar.customColor' | translate }}</mat-label>
            <div matSuffix class="color-picker-wrapper">
              <input type="color" [value]="customBackgroundColor.value" (input)="onCustomColorChange($event)">
            </div>
            <input matInput type="text" [formControl]="customBackgroundColor" placeholder="#FFFFFF">
          </mat-form-field>
        </form>
        <br>
      </div>
    </div>

    <div *ngIf="uploadedImage" class="border-settings">
      <h3>{{ 'employee.avatar.borderSettings' | translate }}</h3>

      <div class="border-input">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'employee.avatar.borderColor' | translate }}</mat-label>
          <div matSuffix class="color-picker-wrapper">
            <input type="color" [value]="borderColorControl.value" (input)="onBorderColorChange($event)">
          </div>
          <input matInput type="text" [formControl]="borderColorControl" placeholder="#000000">
        </mat-form-field>
      
        <mat-form-field appearance="fill">
          <mat-label>{{ 'employee.avatar.borderWidth' | translate }}</mat-label>
          <input matInput type="number" [formControl]="borderWidthControl" placeholder="5">
        </mat-form-field>
      
        <mat-form-field appearance="fill">
          <mat-label>{{ 'employee.avatar.borderRadius' | translate }}</mat-label>
          <input matInput type="number" [formControl]="borderRadiusControl" placeholder="0" min="0" max="50">
        </mat-form-field>
      </div>

      <mat-checkbox [(ngModel)]="darkenEffect">
        {{ 'employee.avatar.darkenEffect' | translate }}
      </mat-checkbox>
    
      <button mat-stroked-button (click)="generatePulsatingBorderGif()">
        {{ 'employee.avatar.generateGif' | translate }}
      </button>

      <div class="gif-preview">
        <div *ngIf="isGifGenerating; else gifContent">
          <app-loading></app-loading>
        </div>
      
        <ng-template #gifContent>
          <ng-container *ngIf="gifDataUrl">
            <img [src]="gifDataUrl" alt="GIF Preview"/>
          </ng-container>
        </ng-template>
      </div>
    </div>

    <div class="save-button-wrapper">
      <button mat-stroked-button type="button" (click)="saveGifImage()" [class.loading-button]="isSavingGif" [disabled]="isSavingGif" color="primary">
        <mat-spinner diameter="20" *ngIf="isSavingGif"></mat-spinner>
        <span *ngIf="!isSavingGif">{{ 'employee.avatar.saveGif' | translate }}</span>
      </button>

      <button mat-raised-button type="button" (click)="saveNewImage()" [class.loading-button]="isSavingImage" [disabled]="isSavingImage" color="primary">
        <mat-spinner diameter="20" *ngIf="isSavingImage"></mat-spinner>
        <span *ngIf="!isSavingImage">{{ 'employee.avatar.save' | translate }}</span>
      </button>
      
    </div>
  </mat-card-content>
</div>
