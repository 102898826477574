import { BillingSettingsService } from '../billing/settings/billing-settings.service';
import { getCurrentAccount } from '../accounts/accounts.reducer';
import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-expired-trial',
  templateUrl: './expired-trial.component.html',
  styleUrls: ['./expired-trial.component.scss']
})
export class ExpiredTrialComponent implements OnInit {
  isProcessingPayment: boolean = false;
  currentDomain: string | null = null;

  constructor(
    private billingSettingsService: BillingSettingsService,
    private auth: AuthService,
    private store: Store,
    private router: Router
  ) {}

  async ngOnInit() {
    if (!this.auth.trialFinishDate || moment().isBefore(this.auth.trialFinishDate)) {
      await this.router.navigate(['']);
    }

    this.store.select(getCurrentAccount).subscribe()
  }

  async openPaymentModal(): Promise<void> {
    this.isProcessingPayment = true;
    this.billingSettingsService.createCheckoutSession().subscribe({
      next: (response: { url: string }) => {
        window.location.href = response.url;
      },
      error: () => {
        this.isProcessingPayment = false;
      }
    });
  }
}
