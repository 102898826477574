<mat-card
  id="handwrittenSignatureCard"
  class="handwritten-signature-section"
>
  <mat-card-header
    class="clickable-header"
    (click)="toggleContent()"
    matTooltipPosition="above"
  >
    <mat-card-title>
      {{ 'employee.handwritten.handwrittenTitle' | translate }}
    </mat-card-title>

    <mat-icon class="expand-icon">{{ isOpen ? 'expand_less' : 'expand_more' }}</mat-icon>
  </mat-card-header>

  <mat-card-content [@expandCollapse]="isOpen ? 'expanded' : 'collapsed'">
    <div class="upload-block">
      <h4>{{ 'employee.handwritten.uploadTitle' | translate }}</h4>
      <input
        type="file"
        class="file-input"
        accept="image/*"
        (change)="onHandwrittenSignatureFileSelected($event)"
        #fileUpload
      />
      <div class="file-upload">
        <div class="file-content">
          <button
            type="button"
            mat-stroked-button
            color="primary"
            class="upload-btn"
            (click)="fileUpload.click()"
          >
            {{ 'employee.handwritten.chooseFile' | translate }}
            <mat-icon>attach_file</mat-icon>
          </button>
          <h6>
            {{ fileName || ('employee.handwritten.noFileSelected' | translate) }}
          </h6>
        </div>
      </div>
      <h5 class="hint">
        {{ 'employee.handwritten.supportedFormats' | translate }}
      </h5>
    </div>

    <div class="generate-block">
      <h4>{{ 'employee.handwritten.generateTitle' | translate }}</h4>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'employee.handwritten.nameLabel' | translate }}</mat-label>
        <input matInput [formControl]="form.get('signatureNameInput')" />
      </mat-form-field>

      <div class="template-chooser">
        <h5>{{ 'employee.handwritten.templateChooserTitle' | translate }}</h5>
        <mat-radio-group [formControl]="form.get('chosenSignatureTemplate')">
          <mat-radio-button
            *ngFor="let style of signatureStyles"
            [value]="style"
          >
            {{ style.label }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <button
        type="button"
        mat-raised-button
        color="primary"
        (click)="generateHandwrittenSignature()"
      >
        {{ 'employee.handwritten.generateButton' | translate }}
      </button>
    </div>

    <div class="preview-block" *ngIf="handwrittenSignaturePreview">
      <h4>{{ 'employee.handwritten.previewTitle' | translate }}</h4>
      <img
        [src]="handwrittenSignaturePreview"
        alt="{{ 'employee.handwritten.previewAlt' | translate }}"
      />
    </div>
  </mat-card-content>
</mat-card>
