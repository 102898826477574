<div class="expired-trial-wrapper">
  <div class="content">
    <h1 class="title">{{ 'trial.expired.title' | translate }}</h1>
    <p class="description">
      {{ 'trial.expired.message' | translate }}
    </p>
    <div class="actions">
      <button mat-raised-button color="primary" type="button"
              (click)="openPaymentModal()"
              [disabled]="isProcessingPayment"
              class="payment-button">
        <mat-icon>credit_card</mat-icon>
        {{ 'trial.expired.addCard' | translate }}
        <div class="spinner-border spinner-border-sm" role="status" *ngIf="isProcessingPayment"></div>
      </button>
    </div>
    <p class="contact-info">
      {{ 'trial.expired.needHelp' | translate }} <a href="mailto:contact&#64;gsignature.com">contact&#64;gsignature.com</a>
    </p>
  </div>
</div>
