import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';

@Component({
  selector: 'app-handwritten-signature',
  templateUrl: './handwritten-signature.component.html',
  styleUrls: ['./handwritten-signature.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state(
        'collapsed',
        style({
          height: '0px',
          overflow: 'hidden',
          opacity: 0
        })
      ),
      state(
        'expanded',
        style({
          height: '*',
          overflow: 'hidden',
          opacity: 1
        })
      ),
      transition('expanded <=> collapsed', animate('300ms ease-in-out'))
    ])
  ]
})
export class HandwrittenSignatureComponent implements OnInit {
  @Input() form: FormGroup;
  fileName = '';
  handwrittenSignaturePreview: string | null = null;
  isOpen = false;

  signatureStyles = [
    { label: 'Alex Brush', font: 'Alex Brush', size: 28, color: '#000000' },
    { label: 'Allura', font: 'Allura', size: 28, color: '#000000' },
    { label: 'Dancing Script', font: 'Dancing Script', size: 28, color: '#000000' },
    { label: 'Great Vibes', font: 'Great Vibes', size: 28, color: '#000000' },
  ];

  constructor(
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    const existing = this.form.get('handwrittenSignature')?.value;
    if (existing && existing.startsWith('data:image/')) {
      this.handwrittenSignaturePreview = existing;
    } else if (existing && existing.startsWith('http')) {
      this.handwrittenSignaturePreview = existing;
    }
  }

  onHandwrittenSignatureFileSelected(event: any) {
    const file = event.target.files?.[0];
    if (!file) return;
    this.fileName = file.name;

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const base64Image = e.target.result;
      this.handwrittenSignaturePreview = base64Image;

      this.form.patchValue({
        handwrittenSignature: base64Image,
      });
    };
    reader.readAsDataURL(file);
  }

  generateHandwrittenSignature() {
    const nameValue = this.form.get('signatureNameInput')?.value;
    const styleValue = this.form.get('chosenSignatureTemplate')?.value;
  
    if (!nameValue || !styleValue) {
      this.toastr.warning(
        this.translate.instant('employee.handwritten.fillDetailsWarning')
      );
      return;
    }
  
    document.fonts.load(`1em "${styleValue.font}"`).then(() => {
      const previewDiv = document.createElement('div');
      previewDiv.style.position = 'absolute';
      previewDiv.style.visibility = 'hidden';
      previewDiv.style.whiteSpace = 'nowrap';
      previewDiv.style.fontFamily = styleValue.font;
      previewDiv.style.fontSize = `${styleValue.size}px`;
      previewDiv.style.color = styleValue.color;
      previewDiv.style.lineHeight = '1.2';
      previewDiv.textContent = nameValue;
  
      document.body.appendChild(previewDiv);
      const textWidth = previewDiv.offsetWidth;
      const textHeight = previewDiv.offsetHeight;
      document.body.removeChild(previewDiv);
  
      const canvas = document.createElement('canvas');
      canvas.width = textWidth + 20;
      canvas.height = textHeight;
  
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        this.toastr.error(
          this.translate.instant('employee.handwritten.canvasNotSupported')
        );
        return;
      }
  
      ctx.font = `${styleValue.size}px "${styleValue.font}"`;
      ctx.fillStyle = styleValue.color;
      ctx.textBaseline = 'top';
      ctx.fillText(nameValue, 10, 10);
  
      const base64Image = canvas.toDataURL('image/png');
      this.handwrittenSignaturePreview = base64Image;
  
      this.form.patchValue({
        handwrittenSignature: base64Image,
      });
    }).catch((err) => {
      this.toastr.error(
        this.translate.instant('employee.handwritten.fontLoadingError')
      );
      console.error('Font loading error:', err);
    });
  }

  toggleContent(): void {
    this.isOpen = !this.isOpen;

    setTimeout(() => {
      this.scrollToCard();
    }, 300);
  }

  scrollToCard(): void {
    const card = document.getElementById('handwrittenSignatureCard');
    if (card) {
      card.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
