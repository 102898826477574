import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BillingSettings } from '../../settings/settings.interface';
import { ClientMonthlyStats } from '../../settings/settings.interface';

@Injectable({
  providedIn: 'root'
})
export class BillingSettingsService {

  constructor(private http: HttpClient) { }

  get(): Observable<BillingSettings> {
    return this.http.get<BillingSettings>('/billing-settings');
  }

  update(payload: BillingSettings): Observable<any> {
    return this.http.post('/billing-settings', payload);
  }

  updatePaymentMethod(paymentMethod: string): Observable<any> {
    return this.http.post('/billing-settings/payment-method', { paymentMethod });
  }  

  updateSubdomains(domain: string, subdomains: Array<{ domain: string }>): Observable<any> {
    return this.http.post(`/billing-settings/subdomains`, { domain, subdomains });
  }

  getAllDomains(): Observable<string[]> {
    return this.http.get<string[]>('/admin/client/all-domains');
  }

  getMonthlyStats(domain: string,): Observable<ClientMonthlyStats[]> {
    return this.http.get<ClientMonthlyStats[]>(`/client-monthly-stats/${domain}`);
  }

  getAggregatedMonthlyStats(domain: string): Observable<ClientMonthlyStats[]> {
    return this.http.get<ClientMonthlyStats[]>(`/client-monthly-stats-aggregated/${domain}`);
  }

  createCheckoutSession(): Observable<{ url: string }> {
    return this.http.post<{ url: string }>('/stripe/create-checkout-session', {});
  }

  verifyCheckoutSession(sessionId: string): Observable<any> {
    return this.http.post('/stripe/verify-checkout-session', { sessionId });
  }
}
